@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.singleProductForm {
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  margin-top: 25px;
  @include respond-to("mobile") {
    box-shadow: none;
    padding: 0px 10px;
  }
}

.topFormSection {
  display: flex;
  gap: 120px;
  @include respond-to("small") {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include respond-to("small") {
      order: 2;
    }
  }
  &__right {
    width: 100%;
    max-width: 266px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    padding: 20px;
    height: max-content;
    @include respond-to("small") {
      order: 1;
    }
    @include respond-to("tablet") {
      max-width: 100%;
    }
    .uploadBtn {
      height: 35px;
      font-size: 12px;
      margin: auto;
      margin-bottom: 20px;

      @include respond-to("tablet") {
        margin-bottom: 30px;
      }
    }
    .imageContainer {
      width: 220px;
      height: 170px;
      @include respond-to("tablet") {
        width: 170px;
        height: 150px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imagesContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 588px;
      overflow-y: scroll;

      @include respond-to("tablet") {
        flex-direction: row;
      }

      .imageDeleteContainer {
        display: flex;
        align-items: center;
        gap: 20px;

        @include respond-to("tablet") {
          flex-direction: column;
        }

        .imageContainer {
          width: 220px;
          height: 170px;
          @include respond-to("tablet") {
            width: 170px;
            height: 150px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

        .deleteContainer {
          border: 1px solid rgba(224, 32, 32, 1);
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

.bottomFormSection {
  margin-top: 20px;
  border-radius: 12px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  &.showBorder {
    border: $light-border;
    padding: 15px 12px;
  }
  &Service {
    max-width: 900px;
  }
}

.priceCover {
  border: 1px solid #c5c5c5;
  border-radius: 12px;
  padding: 15px 12px 0 12px;
  .variantOption {
    display: flex;
    gap: 15px;
    align-items: center;
    @include respond-to("big-tablet") {
      flex-direction: column;
    }
    & > div {
      width: 100%;
      @include respond-to("tablet") {
        order: 2;
      }
    }
    .imageContainer {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      background: $white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 100%;
        background-size: contain;
      }
    }
  }
}

.variantStoreCover {
  padding: 10px 10px 0 10px;
  border: $light-border;
  border-radius: 10px;
  margin-bottom: 15px;
  background: $white-color;
}

.thickTopBorder {
  border-top: 18px solid #c5c5c5;
  background: #f3f3f3;
}

.priceSection {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: flex-end;

  & > div {
    width: 100%;
    min-width: 120px;
    max-width: 170px;
    @include respond-to("big-tablet") {
      max-width: calc(50% - 15px);
    }
    @include respond-to("tablet") {
      min-width: 100%;
      gap: 5px;
    }
  }
  &BigInput {
    & > div {
      max-width: 200px;
    }
  }
}

.flexArrange {
  display: flex;
  gap: 15px;
  align-items: center;
  @include respond-to("tablet") {
    flex-direction: column;
  }
}

.optionContainer {
  margin-top: 15px;
  padding-top: 15px;
  border-top: $light-border;
  text-align: center;
  a {
    cursor: pointer;
  }
}

.useAcrossSection {
  padding: 12px;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: $light-border;
  border-width: 2px;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @include respond-to("mobile") {
    margin-top: 30px;
  }
  .createBtn {
    @include respond-to("mobile") {
      width: 100%;
      margin: 0px 30px 30px 30px;
    }
  }
}
