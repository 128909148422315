@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.menuLayout {
  background: #f8f8f8;
  .navbar {
    min-height: 80px;
    max-height: 125px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;

    @include respond-to("small") {
      padding: 10px;
      gap: 10px;
    }

    .mainLogo {
      width: 100%;
      max-width: 140px;
      max-height: 80px;

      @include respond-to("small") {
        max-width: 110px;
        max-height: 60px;
      }
    }

    .search {
      max-width: 550px;
      min-width: 350px;
      width: 100%;

      @include respond-to("mobile") {
        max-width: 350px;
      }

      @include respond-to("small") {
        max-width: 300px;
        min-width: 70px;
        margin-right: 10px;
      }
    }

    &__left {
      display: flex;
      gap: 10px;
      align-items: center;
      @include respond-to("small") {
        margin-left: 0;
      }
      .cartBtn {
        @include respond-to("small") {
          display: none;
        }
      }
      .cart {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        position: absolute;
        top: -15px;
        right: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .btnContainer {
      @include respond-to("small") {
        display: none;
      }
    }

    &__right {
      display: none;

      @include respond-to("small") {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .rightItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        cursor: pointer;

        p {
          font-size: 12px;
        }

        .cartCount {
          width: 20px;
          height: 20px;
          background: #ad91f5;
          border-radius: 20px;
          font-size: 12px;
          color: $white-color;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -12px;
          top: -6px;
        }
      }
    }
  }

  .mobileSocialMedia {
    width: 100vw;
    margin-top: 80px;
    z-index: 2;
    display: none;
    background: #ffffff;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    // box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.3);

    @include respond-to("big-tablet") {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      // margin-bottom: -40px;
    }

    .socialContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      .iconContainer {
        cursor: pointer;
        height: 20px;
        width: 24px;
        box-shadow: 0px 0px 2px 0px #00000024;
        border: 0.5px solid #9797974d;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bannerContainer {
    position: relative;
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -20px;
    margin-top: 70px;

    @include respond-to("small") {
      height: 200px;
      margin-top: 0;
      margin-bottom: 50px;
    }

    .banner {
      height: 100%;
      width: 100%;

      @include respond-to("small") {
        object-fit: cover;
      }
    }

    .logoContainer {
      box-shadow: 0px 0px 4px 0px #00000024;
      border: 1.5px solid #9797974d;
      height: 100px;
      // width: 104px;
      padding: 0 10px;
      border-radius: 16px;
      background: #ffffff;
      position: absolute;
      bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to("small") {
        height: 72px;
        // width: 76px;
        padding: 0 6px;
        bottom: -35px;
      }

      .logo {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .menuOutlet {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    @include respond-to("big-tablet") {
      margin-top: 36px;
      padding: 0;
    }

    .bottomContent {
      display: none;

      @include respond-to("big-tablet") {
        display: block;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
        gap: 15px;

        .lumiPoweredBy {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
        }

        .storefrontLoc {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 8px 16px;
          border-top: 1px solid #9797974d;
          border-bottom: 1px solid #9797974d;
          margin-bottom: 30px;

          .address {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }
}
