@import "./mixins";

h1 {
  font-size: 48px; // adjust font size to the ones on the design
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 35px;
  }
}

h3 {
  font-size: 32px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 29px;
  }
}

h4 {
  font-size: 24px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 21px;
  }
}

h5 {
  font-size: 18px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 15px;
  }
}

h6 {
  font-size: 16px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 14px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
  color: $light-gray-color;
  font-size: 16px;

  @include respond-to("tablet") {
    font-size: 14px;
  }
}

label {
  color: $dark-color;
  font-size: 14px;
  line-height: 1.5;

  @include respond-to("tablet") {
    font-size: 13px;
  }
}

a {
  font-size: 16px;
  font-weight: normal;
  color: $gray-color;
  text-decoration: none;

  @include respond-to("tablet") {
    font-size: 14px;
  }
}

.default-font-size {
  font-size: 16px;

  @include respond-to("tablet") {
    font-size: 14px;
  }
}

.big-bold-text-size {
  font-size: 55px;
}

.big-text-size {
  font-size: 20px;

  @include respond-to("tablet") {
    font-size: 15px;
  }
}

.small-text-size {
  font-size: 14px;

  @include respond-to("tablet") {
    font-size: 12px;
  }
}

.smallest-text-size {
  font-size: 12px;

  @include respond-to("tablet") {
    font-size: 12px;
  }
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-semibold {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

input::placeholder {
  font-size: 16px;

  @include respond-to("tablet") {
    font-size: 14px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.failed--text {
  color: #e02020;
  background: #feeaea;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 500;
}

.muted--text {
  color: #696677;
  background-color: #eeeeee;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 8px;
}

.chilled--text {
  color: #7647ee;
  background: #f3f1fc;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 8px;
}
.note--text {
  color: #7647ee;
}

.cancell--red {
  color: #fd561f;
}
.time--green {
  color: #19ae57;
}
