@import "../../../assets/styles/base/variables";

.input-field {
  width: 100%;

  input,
  .inputCover {
    height: 50px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    padding: 0 1em 2px 1em;
    background: #f3f1fc;
    width: 100%;
    outline: none;

    &:hover {
      border-color: $purple-color;
    }

    &:focus {
      border-color: $purple-color;
    }

    &:disabled,
    &.isDisabled {
      cursor: not-allowed;
      background: rgba(0, 0, 0, 0.05) !important;
      color: -internal-light-dark(
        rgb(84, 84, 84),
        rgb(170, 170, 170)
      ) !important;
      border-color: rgba(151, 151, 151, 0.8) !important;
    }

    &.error-border {
      border-color: $danger-color !important;
    }

    /* Chrome,
    Safari,
    Edge,
    Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .smallInput {
    height: 32px;
  }

  .inputCover {
    height: auto;
    display: flex;
    align-items: center;

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    input {
      border: none;
      background: inherit !important;
    }

    &.isDisabled {
      input {
        &:disabled {
          background: none !important;
        }
      }
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;
    &.show {
      visibility: visible;
    }
  }

  .labelHighlightBg {
    background: #fdefd8;
    max-width: fit-content;
    padding-top: 2px;
  }
}
