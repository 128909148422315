@import "../../../../../assets/styles/base/mixins";

.variantModalContainer {
  display: flex;
  flex-direction: column;

  .closeIcon {
    align-self: flex-end;
    cursor: pointer;
    width: 20px;
  }

  .imgContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 285px;

    @include respond-to("big-tablet") {
      height: 220px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .baseImagesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;

    .arrowContainer {
      height: 32px;
      width: 32px;
      border-radius: 10.67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7647ee;
      cursor: pointer;
    }

    .imagesContainer {
      max-width: 427px;
      height: 76px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16) inset;
      display: flex;
      align-items: center;
      gap: 20px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      border-radius: 7.91px;
      padding: 0 8px;

      @include respond-to("big-tablet") {
        gap: 12px;
        padding: 0 4px;
      }

      .imageItem {
        position: relative;
        height: 64px;
        width: 64px;
        border: 1px solid #7647ee;
        border-radius: 7.91px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7.91px;
        }

        .imageCover {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.476);
          border-radius: 7.91px;
        }
      }
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;

    .descriptionContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
      margin-top: 8px;

      .detailsContainer {
        background: #c5c5c54d;
        padding: 2px 12px;
        border-radius: 812px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: self-start;
        margin-bottom: 8px;
        align-items: flex-start;

        p {
          color: #696677;
          font-size: 14px;
          font-weight: 500;
        }
      }

      p {
        span {
          font-size: 14px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .variantContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;

      p {
        font-weight: 700;
      }

      .variants {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .variantItemContainer {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          border-radius: 8px;
          padding: 8px 16px;
          box-shadow: 0px 0px 3px 0px #00000026;
          border: 0.75px solid #9797974d;

          @include respond-to("big-tablet") {
            padding: 8px;
          }

          .variantImgContainer {
            height: 60px;
            width: 60px;
            border-radius: 4px;

            .variantImg {
              height: 100%;
              width: 100%;
              border-radius: 4px;
              object-fit: cover;
            }
          }

          .variantContent {
            display: flex;
            flex-direction: column;
            margin: auto 0;
            .detailsContainer {
              background: #c5c5c54d;
              padding: 2px 12px;
              border-radius: 812px;
              display: flex;
              align-items: center;
              justify-content: center;
              align-self: self-start;
              margin-bottom: 8px;
              margin-top: 8px;

              p {
                color: #696677;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .description {
              color: #15112d;
              font-size: 14px;
              line-height: 24px;
              font-weight: 600;

              .productPrice {
                font-size: 12px;
              }
            }
          }

          .selectButtonsContainer {
            display: flex;
            align-items: center;
            margin: auto;
            margin-right: 0;

            .decrement,
            .increment,
            .value {
              width: 32px;
              height: 32px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 16px;
            }

            .decrement {
              border: 1px solid #7647ee;
              cursor: pointer;
            }

            .increment {
              background: #7647ee;
              color: #fff;
              cursor: pointer;
            }

            .value {
              margin: 0 8px;
              background: #f2edfe;
            }
          }
        }
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;

  .closeIcon {
    cursor: pointer;
    align-self: flex-end;
    width: 20px;
  }

  .imgContainer {
    margin-top: 10px;
    width: 100%;
    height: 285px;

    @include respond-to("big-tablet") {
      height: 220px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .baseImagesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;

    .arrowContainer {
      height: 32px;
      width: 32px;
      border-radius: 10.67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7647ee;
      cursor: pointer;
    }

    .imagesContainer {
      max-width: 427px;
      height: 76px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16) inset;
      display: flex;
      align-items: center;
      gap: 20px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      border-radius: 7.91px;
      padding: 0 8px;

      @include respond-to("big-tablet") {
        gap: 12px;
        padding: 0 4px;
      }

      .imageItem {
        position: relative;
        height: 64px;
        width: 64px;
        border: 1px solid #7647ee;
        border-radius: 7.91px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7.91px;
        }

        .imageCover {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.476);
          border-radius: 7.91px;
        }
      }
    }
  }

  .contentContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .detailsContainer {
      background: #c5c5c54d;
      padding: 2px 12px;
      border-radius: 812px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: self-start;
      margin-bottom: 8px;
      margin-top: 8px;

      p {
        color: #696677;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .description {
      color: #15112d;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      .productPrice {
        font-size: 14px;
      }
    }

    p {
      span {
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .preparationTime {
      color: #696677;
      margin-top: 12px;
      span {
        color: #15112d;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .separator {
      margin: 12px 0;
      border: 0.75px dashed #9797974d;
    }

    .baseContainer {
      display: flex;
      align-items: center;

      @include respond-to("big-tablet") {
        flex-direction: column;
      }

      .selectQuantityContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1.5;

        @include respond-to("big-tablet") {
          flex: 1;
        }

        p {
          color: #696677;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .selectButtonsContainer {
          display: flex;
          align-items: center;

          .decrement,
          .increment,
          .value {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
          }

          .decrement {
            border: 1px solid #7647ee;
            cursor: pointer;
          }

          .increment {
            background: #7647ee;
            color: #fff;
            cursor: pointer;
          }

          .value {
            margin: 0 8px;
            background: #f2edfe;
          }
        }
      }

      .button {
        margin-top: 28px;
        flex: 2;
        width: 100%;

        @include respond-to("big-tablet") {
          flex: 1;
        }
      }
    }
  }
}
