@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.allFoodMenu {
  padding-bottom: 50px;

  .menuItemScrollContainer {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    column-gap: 48px;
    padding-right: 10px;
    scrollbar-width: none;
    margin-bottom: 32px;
    border-bottom: 2px solid #ededed;

    @include respond-to("big-tablet") {
      column-gap: 24px;
    }

    .menuItemScroll {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;

      @include respond-to("big-tablet") {
        font-size: 14px;
      }
    }

    .active {
      border-bottom: 4px solid #7647ee;
      color: #7647ee;
    }

    .inactive {
      border-bottom: transparent;
      color: #696677;
    }
  }

  &__content {
    border-radius: 16px;
    padding-bottom: 24px;
    background: $white-color;
    min-height: 500px;

    .searchSection {
      padding: 24px;
      max-width: 546px;
    }
  }
}
