@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.mobileMenuSection {
  display: none;
  @include respond-to("small") {
    display: flex;
  }

  .mobileMenuCover {
    background: $white-color;
    padding: 10px 5% 0 5%;
    position: fixed;
    width: 100%;
    z-index: 3001;
    left: 0;
    bottom: 0;
    &.active {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-top: 2px solid $purple-color;
      & ~ .mobileMenuOverlay {
        display: block !important;
      }
    }

    .mobileMenu {
      display: flex;
      gap: 10px;
      justify-content: space-around;
      align-items: flex-end;
      height: 75px;
      @include respond-to("tablet") {
        justify-content: space-between;
      }

      .inventoryCover {
        height: 32px;
        padding: 3px 15px;
        display: flex;
        justify-content: center;
        background: #1e016f;
        border: 2px solid $purple-color;
        border-radius: 25px;
      }
      .pointer {
        width: 15px;
        height: 15px;
        border-radius: 5px;
        background: transparent;
        display: block;
        margin: 8px auto -10px auto;
        transform: rotate(45deg);
      }

      &__item {
        text-align: center;
        width: 20%;
        p {
          margin-top: 5px;
          @include respond-to("mobile") {
            font-size: 10px;
          }
        }

        &.active {
          .pointer {
            background: $purple-color;
          }
          p {
            color: $purple-color;
          }
        }
      }
    }

    .subMenuRow {
      display: flex;
      gap: 20px;
      padding: 10px 0;
      border-bottom: $light-border;
      p {
        margin-bottom: 0 !important;
        color: $dark-color;
      }
      img {
        width: 20px;
        object-fit: none;
      }
    }
    .splited {
      border: none;
      width: 100%;
    }
  }

  .mobileMenuOverlay {
    height: 100%;
    width: 100%;
    opacity: 0.46;
    background-color: #212121;
    z-index: 3000;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }
}

.deskTopSidebar {
  position: sticky;
  top: 0;
  height: 100%;
  display: flex;
  z-index: 2;
  @include respond-to("small") {
    display: none;
  }

  &.allowHover:hover {
    .collapseMode {
      position: absolute;
      width: 175px;
      left: 95px;
      max-height: 100vh;
      overflow-y: scroll;
      z-index: 1;
      padding: 40px 15px 25px 15px;
      .collapseSection {
        left: 0;
        display: block;
      }
    }
  }

  &__item {
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100%;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-shrink: 0;
  }
  &__left {
    background: $white-color;
    width: 95px;
    align-items: center;
    text-align: center;
  }
  &__right {
    background: $light-ash;
    width: 175px;
    padding: 40px 15px 25px 15px;
    overflow-x: auto;
    &.closeMode,
    &.collapseMode {
      width: 0;
      padding: 0;
    }

    &.closeMode {
      .collapseSection {
        display: none;
      }
    }

    &.collapseMode {
      .collapseSection {
        right: auto;
        .collapseIcon {
          transform: rotate(180deg);
          margin-left: 0;
          margin-right: auto;
        }
        p {
          margin-left: -30px;
        }
      }
    }

    .collapseIcon {
      display: block;
      cursor: pointer;
      width: 30px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: right;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: $white-color;
      margin-left: auto;
      border: 1px solid $purple-color;
    }

    .collapseSection {
      position: absolute;
      right: 0;
      top: 25px;
      &:hover {
        // p {
        //   display: block;
        // }
      }
      p {
        display: none;
        width: max-content;
        margin-right: -30px;
      }
    }
  }

  .menuCover {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &.modified {
      gap: 0px;
    }
    &.modified2 {
      gap: 30px;
      padding-left: 10px;
    }
    &__item {
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      &.isActive {
        svg path {
          stroke: $purple-color !important;
        }
        p {
          color: $purple-color !important;
        }
      }

      &:hover {
        svg path {
          stroke: #ad91f5;
        }
        p {
          color: #ad91f5;
        }
      }

      svg path {
        stroke: $dark-color;
      }

      p {
        font-size: 11px !important;
        color: $dark-color;
      }
    }
  }

  .navlink {
    color: $dark-color;
    // margin-bottom: 30px;
    font-size: 14px;
    &:hover {
      color: $purple-color;
      text-decoration: none;
    }
    &.active {
      color: $purple-color;
    }
  }
  .navlinkCover {
    padding: 15px 0;
  }

  .parentNavlink {
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;

    &__top {
      cursor: pointer;
      display: flex;
      gap: 15px;
      justify-content: space-between;
      align-items: center;
    }

    &__icon {
      width: 18px;
      height: 18px;
      padding: 0;
      border-radius: 3px;
      border: 1px solid $dark-color;
    }
    &__active {
      .parentNavlink__top {
        .parentNavlink__icon {
          border-color: $purple-color;
        }
      }
    }
  }

  .businessName {
    border: 5px solid $secondary-bg;
    background: $purple-color;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    margin: auto auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
