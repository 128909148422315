// Edit this section an set color variable with occurdance with the design
$main--bg: #e5e5e5;
$secondary-bg: #e4dafc;
$tertiary-bg: #f8f8f8;
$purple-color: #7647ee;
$dark-purple-color: #1e016f;
$white-color: #ffffff;
$dark-color: #15112d;
$gray-color: #9091b3;
$light-gray-color: #696677;
$danger-color: #dc3545;
$lumi-purple: #f2edfe;
$dark-yellow: #f7ad3c;
$success-color: #19ae57;
$orange-color: #fd561f;
$lumi-green: #19ae57;
$lumi-light-green: #75ce9a;
$lumi-get-started: #f7f6fd;
$light-ash: #eeeef0;
$light-purple: #f3f1fc;
$deep-yellow: #ffddd2;
$light-green: #d1efdd;
$light-cancel: #e8e8e8;
$light-green-lumi: #a3dfbc;
$ash-color: #efefef;
$subcription-plain: #ffeee9;

.white {
  background: $white-color !important;
  &--text {
    color: $white-color !important;
  }
}
.purple {
  background: $purple-color !important;
  &--text {
    color: $purple-color !important;
  }
  &--border {
    border: 1px solid #766df4;
  }
}
.purple--border {
  border: 1px solid $purple-color !important;
}

.gray {
  background: $light-gray-color;
  &--text {
    color: $light-gray-color;
  }
}
.dark {
  background: $dark-color;
  &--text {
    color: $dark-color;
  }
}

.error {
  background: $danger-color !important;
  &--text {
    color: $danger-color !important;
  }
  &--squared {
    color: $danger-color;
    background: #feeaea;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 14px !important;
  }
}

.orange {
  background: $orange-color;
  &--text {
    color: $orange-color;
  }
  &--subscription {
    background: $subcription-plain;
  }
}

.yellow {
  background: $dark-yellow;
  &--text {
    color: $dark-yellow;
  }
}

.ash {
  background: $ash-color;
  &--text {
    color: $ash-color;
  }
}

.success {
  background: $success-color;
  &--text {
    color: $success-color;
  }
  &--squared {
    color: $success-color;
    background: #e5fff0;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 14px !important;
  }
  &--rounded {
    color: $success-color;
    background: #e5fff0;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 14px !important;
  }
  &--kitchenReady {
    font-size: 40px;
    font-weight: bold;
    color: #19ae57;
    background: #e5fff0;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 5px;
    text-align: center;
  }
}

.pending {
  background: $dark-yellow;
  &--text {
    color: $dark-yellow;
  }
  &--squared {
    color: $dark-yellow;
    background: #fff8e5;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 14px !important;
  }
  &--rounded {
    padding: 7px 8px;
    color: $dark-yellow;
    background: #fff8e5;
    display: inline-block;
    border-radius: 12px;
    font-size: 14px !important;
  }
  &--kitchenInprogress {
    font-size: 40px;
    font-weight: bold;
    color: #f7ad3c;
    background: #fef7eb;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 5px;
    text-align: center;
  }
}

.comingSoon {
  background: $dark-purple-color !important;
  &--text {
    color: $white-color !important;
  }
  &--rounded {
    color: $white-color !important;
    background: $dark-purple-color !important;
    display: inline-block;
    padding: 13px 16px;
    border-radius: 5px;
  }
  &--connect {
    color: $lumi-green !important;
    background: #e8f7ee !important;
    display: inline-block;
    padding: 13px 16px;
    border-radius: 8px;
    font-weight: 700;
  }
  &--app {
    font-size: 10px;
    font-weight: bold;
    color: #f7ad3c;
    background: #fef7eb;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
  }

  &--disconnect {
    color: $orange-color !important;
    background: $subcription-plain !important;
    display: inline-block;
    padding: 13px 16px;
    border-radius: 8px;
    font-weight: 700;
  }
}
.warning {
  background: #fef7eb;
  border-radius: 12px;
}

$shadow: 0px 4px 20px rgba(135, 126, 144, 0.15);
$purple-border: 1px solid #766df4;
$light-border: 1px solid #ddd;
$input-border: 0.5px solid #979797;
