@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";
.subscriptionFeatureTable {
  background: $white-color;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  padding: 50px 24px 24px 24px;
  position: relative;
  .header {
    display: flex;
    p {
      font-size: 16px;
      //width: 19.5%;
      padding: 0px 24px 10px 24px;
      &:first-child {
        //width: 22%;
        //padding-left: 0;
      }
    }
  }
  .title {
    text-transform: uppercase;
    font-size: 14px;
    background: $light-purple
  }
}
