@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.subscriptionSelection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 3.5em;

  @include respond-to("big-tablet") {
    padding-top: 1.5em;
  }

  &__header {
    background: $white-color;
    padding: 20px 15px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .instruction p,
    .instruction h3 {
      max-width: 600px;
      margin: auto;
    }
  }
  &__desktop,
  &__mobile {
    flex-direction: column;
    gap: 8px;
  }
  &__desktop {
    display: flex;
    height: auto;
    overflow-y: hidden;
    @include respond-to("large") {
      display: none;
    }
  }
  &__mobile {
    display: none;
    @include respond-to("large") {
      display: flex;
    }
  }
}